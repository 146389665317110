import {
  ClubNewsTargetTypes,
  FaceCallVideoThemeTypes,
  FaceCallVideosQueryTypes,
  NormalTagTypes,
  NotificationActionTypes,
  NotificationServiceTypes,
  NotificationTypes,
  RegionTypes,
  ServiceCategoryRouteTypes,
  ServiceCategoryTypes,
  ServiceRouteTypes,
  ServiceTypes,
  SmsStatusTypes,
} from "../types/types";

export const data = {
  themeValidDate: {
    Christmas: ["12", "01", "12", "30"],
    Halloween: ["10", "01", "10", "31"],
    NewYear: ["12", "31", "01", "10"],
  },
  services: [
    {
      id: 2,
      service: ServiceTypes.CLUB_APP,
      serviceRoute: ServiceRouteTypes.CLUB_APP,
    },
    {
      id: 7,
      service: ServiceTypes.CHECK_IN,
      serviceRoute: ServiceRouteTypes.CHECK_IN,
    },
    {
      id: 3,
      service: ServiceTypes.CLUB_SERVICE,
      serviceRoute: ServiceRouteTypes.CLUB_SERVICE,
    },
    {
      id: 5,
      service: ServiceTypes.SALES_188,
      serviceRoute: ServiceRouteTypes.SALES_188,
    },
    {
      id: 8,
      service: ServiceTypes.TRIAL_MODE,
      serviceRoute: ServiceRouteTypes.TRIAL_MODE,
    },
    {
      id: 4,
      service: ServiceTypes.MKT,
      serviceRoute: ServiceRouteTypes.MKT,
    },
    {
      id: 6,
      service: ServiceTypes.TECHPRESSO,
      serviceRoute: ServiceRouteTypes.TECHPRESSO,
    },
  ],
  serviceItems: [
    {
      id: 1,
      service: ServiceTypes.CLUB_APP,
      serviceRoute: ServiceRouteTypes.CLUB_APP,
      title: "WF Club App",
      items: [
        {
          id: 1,
          category: ServiceCategoryTypes.DASHBOARD,
          categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
          subItems: [],
        },
        {
          id: 4,
          category: ServiceCategoryTypes.DESIGN,
          categoryRoute: ServiceCategoryRouteTypes.DESIGN,
          subItems: [
            {
              id: 1,
              subItemName: "Home",
              routeName: "home",
            },
            {
              id: 2,
              subItemName: "Event Banner",
              routeName: "event-banner",
            },
            {
              id: 3,
              subItemName: "Go, Go Live!",
              routeName: "go-go-live",
            },
          ],
        },
        {
          id: 2,
          category: ServiceCategoryTypes.CONTENT,
          categoryRoute: ServiceCategoryRouteTypes.CONTENT,
          subItems: [
            {
              id: 1,
              subItemName: "Push Notification",
              routeName: "push-notification",
            },
            {
              id: 2,
              subItemName: "Face Call",
              routeName: "face-call",
            },
            {
              id: 3,
              subItemName: "Important Notice",
              routeName: "important-notice",
            },
          ],
        },
        {
          id: 3,
          category: ServiceCategoryTypes.GRADUATION,
          categoryRoute: ServiceCategoryRouteTypes.GRADUATION,
          subItems: [
            {
              id: 1,
              subItemName: "Waiting for Review",
              routeName: "waiting-for-review",
            },
            {
              id: 2,
              subItemName: "All Applications",
              routeName: "all-applications",
            },
            {
              id: 3,
              subItemName: "Kids Info",
              routeName: "kids-info",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      service: ServiceTypes.CHECK_IN,
      serviceRoute: ServiceRouteTypes.CHECK_IN,
      title: "Check-in App",
      items: [
        {
          id: 1,
          category: ServiceCategoryTypes.DASHBOARD,
          categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
          subItems: [],
        },
        {
          id: 2,
          category: ServiceCategoryTypes.CONTENT,
          categoryRoute: ServiceCategoryRouteTypes.CONTENT,
          subItems: [],
        },
        {
          id: 3,
          category: ServiceCategoryTypes.ADMIN_SETTINGS,
          categoryRoute: ServiceCategoryRouteTypes.ADMIN_SETTINGS,
          subItems: [],
        },
      ],
    },
    {
      id: 2,
      service: ServiceTypes.CLUB_SERVICE,
      serviceRoute: ServiceRouteTypes.CLUB_SERVICE,
      title: "Club Service",
      items: [
        {
          id: 1,
          category: ServiceCategoryTypes.DASHBOARD,
          categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
          subItems: [],
        },
        {
          id: 2,
          category: ServiceCategoryTypes.CONTENT,
          categoryRoute: ServiceCategoryRouteTypes.CONTENT,
          subItems: [],
        },
        {
          id: 3,
          category: ServiceCategoryTypes.ADMIN_SETTINGS,
          categoryRoute: ServiceCategoryRouteTypes.ADMIN_SETTINGS,
          subItems: [],
        },
      ],
    },
    {
      id: 3,
      service: ServiceTypes.SALES_188,
      serviceRoute: ServiceRouteTypes.SALES_188,
      title: "Sales 188 App",
      items: [
        {
          id: 1,
          category: ServiceCategoryTypes.DASHBOARD,
          categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
          subItems: [],
        },
        {
          id: 2,
          category: ServiceCategoryTypes.CONTENT,
          categoryRoute: ServiceCategoryRouteTypes.CONTENT,
          subItems: [],
        },
        {
          id: 3,
          category: ServiceCategoryTypes.ADMIN_SETTINGS,
          categoryRoute: ServiceCategoryRouteTypes.ADMIN_SETTINGS,
          subItems: [],
        },
      ],
    },
    {
      id: 7,
      service: ServiceTypes.TRIAL_MODE,
      serviceRoute: ServiceRouteTypes.TRIAL_MODE,
      title: "Trial Mode",
      items: [
        {
          id: 1,
          category: ServiceCategoryTypes.DATA_ANALYSIS,
          categoryRoute: ServiceCategoryRouteTypes.DATA_ANALYSIS,
          subItems: [
            { subItemName: "Dashboard", routeName: "dashboard" },
            {
              id: 1,
              subItemName: "Leads Detail Download",
              routeName: "leads-detail-download",
            },
            { id: 2, subItemName: "Campaign ID", routeName: "campaign-id" },
          ],
        },
        {
          id: 2,
          category: ServiceCategoryTypes.CONTENT,
          categoryRoute: ServiceCategoryRouteTypes.CONTENT,
          subItems: [
            {
              id: 1,
              subItemName: "Local Event",
              routeName: "local-event",
            },
            {
              id: 2,
              subItemName: "Caring CTA",
              routeName: "caring-cta",
            },
            {
              id: 3,
              subItemName: "Journey Card",
              routeName: "journey-card",
            },
            {
              id: 4,
              subItemName: "Trial Content",
              routeName: "trial-content",
            },
            {
              id: 5,
              subItemName: "Trial Content Upload",
              routeName: "trial-content-upload",
            },
          ],
        },
        {
          id: 3,
          category: ServiceCategoryTypes.TRIAL_MODE_PUSH_NOTIFICATION,
          categoryRoute: ServiceCategoryRouteTypes.TRIAL_MODE_PUSH_NOTIFICATION,
          subItems: [],
        },
      ],
    },
    {
      id: 4,
      service: ServiceTypes.MKT,
      serviceRoute: ServiceRouteTypes.MKT,
      title: "Marketing Service",
      items: [
        {
          id: 1,
          category: ServiceCategoryTypes.DASHBOARD,
          categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
          subItems: [],
        },
        {
          id: 2,
          category: ServiceCategoryTypes.CONTENT,
          categoryRoute: ServiceCategoryRouteTypes.CONTENT,
          subItems: [
            {
              id: 1,
              subItemName: "Vendor Reports",
              routeName: "vendor-reports",
            },
            {
              id: 2,
              subItemName: "Ad Exclusion List",
              routeName: "ad-exclusion-list",
            },
          ],
        },
        {
          id: 3,
          category: ServiceCategoryTypes.ADMIN_SETTINGS,
          categoryRoute: ServiceCategoryRouteTypes.ADMIN_SETTINGS,
          subItems: [],
        },
      ],
    },
    {
      id: 5,
      service: ServiceTypes.TECHPRESSO,
      serviceRoute: ServiceRouteTypes.TECHPRESSO,
      title: "Techpresso App",
      items: [
        {
          id: 1,
          category: ServiceCategoryTypes.DASHBOARD,
          categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
          subItems: [],
        },
        {
          id: 2,
          category: ServiceCategoryTypes.CONTENT,
          categoryRoute: ServiceCategoryRouteTypes.CONTENT,
          subItems: [],
        },
        {
          id: 3,
          category: ServiceCategoryTypes.ADMIN_SETTINGS,
          categoryRoute: ServiceCategoryRouteTypes.ADMIN_SETTINGS,
          subItems: [],
        },
      ],
    },
  ],
  notificationServiceTypes: [
    {
      id: 1,
      type: NotificationServiceTypes.CLUB_NEWS,
    },
    {
      id: 2,
      type: NotificationServiceTypes.MEMBERSHIP,
    },
    {
      id: 3,
      type: NotificationServiceTypes.EVENT,
    },
    {
      id: 4,
      type: NotificationServiceTypes.TELEPHONE_ENGLISH,
    },
    {
      id: 5,
      type: NotificationServiceTypes.CAP,
    },
    {
      id: 6,
      type: NotificationServiceTypes.GRADUATION_APPLY,
    },
    {
      id: 7,
      type: NotificationServiceTypes.GO_GO_LIVE,
    },
    {
      id: 8,
      type: NotificationServiceTypes.WARRANTY,
    },
    {
      id: 9,
      type: NotificationServiceTypes.CS_ONE_ONE_BOARD,
    },
    {
      id: 10,
      type: NotificationServiceTypes.DELIVERY,
    },
    {
      id: 11,
      type: NotificationServiceTypes.SCHEDULED,
    },
  ],
  mockRegularNotifications: [
    {
      id: 1,
      region: RegionTypes.HONG_KONG,
      title: '謝謝{"kidName"}參加今天的Go, Go Live!，記得複習活動的重點',
      message:
        "我們非常重視您的寶貴意見，只需1分鐘時間便可完成問卷，謝謝您的反饋。",
      type: NormalTagTypes.GO_GO_LIVE,
      condition: "After complete the session",
      date: "",
      isSent: false,
      linksTo: "Telephone English / Attending list",
      buttonText: "View my booking",
      smsStatus: "Send SMS if member is not using the app",
    },
    {
      id: 2,
      region: RegionTypes.TAIWAN,
      title: "寰宇家庭已經收到您寄來要進行更換與維修的產品",
      message: "預計將於7-10個工作天寄達通訊地，敬請留意！",
      type: NormalTagTypes.WARRANTY,
      condition: "Request received",
      date: "",
      isSent: false,
      linksTo: "Telephone English / Attending list",
      buttonText: "View my booking",
      smsStatus: "",
    },
  ],
  mockManualNotifications: [
    {
      id: 1,
      region: RegionTypes.HONG_KONG,
      title: "香港特別行政區成立紀念日活動及產品更換服務暫停",
      message:
        "寰宇家庭俱樂部將於香港特別行政區成立紀念日（7月1日）休息，美語活動、電話美語、一般查詢及產品更換服務暫停。",
      type: NormalTagTypes.EVENT,
      condition: "Newly added events",
      date: "2023-09-29T00:00:00",
      isSent: false,
      recipient: "All members",
      linkTo: "",
      cdt: "2023-06-01T11:42:42",
      status: NormalTagTypes.SCHEDULED,
    },
    {
      id: 2,
      region: RegionTypes.TAIWAN,
      title: "5月會員推薦計劃 - 推廣新登場",
      message:
        "送您『免費』會籍！推薦「友」禮！於5月1日至5月31日期間推薦好友經您的專屬連結填交資料及購買教材或體驗World Family Club 英語活動，即有機會獲得積分獎賞！憑積分即可換領『免費』會籍！詳情請參閱 https://bit ly/3LOrp6o",
      type: NormalTagTypes.OTHERS,
      condition: "Newly added events",
      date: "2023-05-23T00:00:00",
      isSent: true,
      recipient: "Selected members",
      linkTo: "",
      cdt: "2023-06-01T11:42:42",
      status: NormalTagTypes.SENT,
    },
  ],
  mockEventSessions: [
    {
      id: 1,
      title: "3230310701",
      date: "2023-07-01",
      time: "10:30-11:30",
      city: "台北",
    },
    {
      id: 2,
      title: "3230310702",
      date: "2023-07-01",
      time: "13:30-14:30",
      city: "台北",
    },
  ],
  notificationTypes: [
    {
      id: 1,
      typeName: NotificationTypes.SPECIFIC_EVENT,
    },
    {
      id: 2,
      typeName: NotificationTypes.PROMOTE,
    },
    {
      id: 3,
      typeName: NotificationTypes.SELECTED_MEMBERS,
    },
  ],
  notificationActionTypesForAnnouncementBar: [
    {
      id: 1,
      typeName: NotificationActionTypes.NONE,
      typeValue: "No navigation",
    },
    {
      id: 2,
      typeName: NotificationActionTypes.APP_SCREEN,
      typeValue: "App Screen",
    },
    {
      id: 3,
      typeName: NotificationActionTypes.APP_EMBED_URL,
      typeValue: "Embed URL",
    },
    {
      id: 4,
      typeName: NotificationActionTypes.OPEN_BROWSER,
      typeValue: "Open browser",
    },
  ],
  notificationActionTypes: [
    {
      id: 1,
      typeName: NotificationActionTypes.NONE,
      typeValue: "none",
    },
    {
      id: 2,
      typeName: NotificationActionTypes.APP_SCREEN,
      typeValue: "AppScreen",
    },
    {
      id: 3,
      typeName: NotificationActionTypes.APP_EMBED_URL,
      typeValue: "AppEmbedURL",
    },
    {
      id: 4,
      typeName: NotificationActionTypes.OPEN_BROWSER,
      typeValue: "OpenBrowser",
    },
  ],
  smsStatusTypes: [
    {
      id: 1,
      typeName: SmsStatusTypes.DO_NOT_SEND,
    },
    {
      id: 2,
      typeName: SmsStatusTypes.SEND_IF_NO_APP,
    },
    {
      id: 3,
      typeName: SmsStatusTypes.SEND,
    },
  ],
  mockExtraStaffList: [
    {
      id: "P141",
      name: "Joy Hung",
    },
    {
      id: "P290",
      name: "Angela Hsieh",
    },
  ],
  clubNewsTargetTypes: [
    {
      id: 1,
      typeName: ClubNewsTargetTypes.ALL_MEMBERS,
    },
    {
      id: 2,
      typeName: ClubNewsTargetTypes.SELECTED_MEMBERS,
    },
  ],
  mockListOfDesignatedStaff: [
    {
      id: "6327",
      name: "Dale Hsu",
    },
    {
      id: "P460",
      name: "Antonella Gismundi",
    },
  ],
  mockListOfExtraStaff: [
    {
      id: "P141",
      name: "Joy Hung",
    },
    {
      id: "P290",
      name: "Angela Hsieh",
    },
  ],
  mockAllStaff: [
    {
      id: "P606",
      name: "Wade Wang",
    },
    {
      id: "P610",
      name: "Taco Chang",
    },
  ],
  faceCallVideosQueryTypes: [
    {
      id: 2,
      typeName: FaceCallVideosQueryTypes.IN_USE,
    },
    {
      id: 3,
      typeName: FaceCallVideosQueryTypes.UPCOMING,
    },
    {
      id: 4,
      typeName: FaceCallVideosQueryTypes.THEME,
    },
    {
      id: 5,
      typeName: FaceCallVideosQueryTypes.NOT_IN_USE,
    },
  ],
  mockFaceCallVideos: [
    {
      id: 1,
      title: "Flour, Sugar, Milk and Butter",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.HALLOWEEN,
      publishDate: "2023-10-01",
      stopDate: "2023-10-31",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 2,
      title: "Happy Birthday to You",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.NONE,
      publishDate: "2023-08-01",
      stopDate: "",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 3,
      title: "Huey Takes a Red Ballon",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.NEW,
      publishDate: "2023-08-01",
      stopDate: "",
      isNewOfThisMonth: true,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 4,
      title: "Just Like Me",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.NONE,
      publishDate: "",
      stopDate: "",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 5,
      title: "Balloons",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.NONE,
      publishDate: "2023-11-01",
      stopDate: "2023-11-30",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 6,
      title: "Donald's Dream",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.NONE,
      publishDate: "2023-11-01",
      stopDate: "2023-11-30",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 7,
      title: "Eyes and Ears",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.NONE,
      publishDate: "2023-11-01",
      stopDate: "2023-11-30",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 8,
      title: "Hush! Hush!",
      performer: ["Alana"],
      theme: FaceCallVideoThemeTypes.NONE,
      publishDate: "2023-11-01",
      stopDate: "2023-11-30",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
    {
      id: 9,
      title: "Clap Your Hands!",
      performer: ["Alana", "Alex"],
      theme: FaceCallVideoThemeTypes.CHRISTMAS,
      publishDate: "2023-12-01",
      stopDate: "2023-12-30",
      isNewOfThisMonth: false,
      fileUrl: "some url",
      vimeoUrl:
        "https://player.vimeo.com/external/248757026.hd.mp4?s=a0249e6a94c163541d3095f64d9ba0bf1c3d34a7&profile_id=174",
    },
  ],
  faceCallVideoThemes: [
    {
      id: 1,
      name: FaceCallVideoThemeTypes.CHRISTMAS,
    },
    {
      id: 2,
      name: FaceCallVideoThemeTypes.HALLOWEEN,
    },
    {
      id: 3,
      name: FaceCallVideoThemeTypes.NEW_YEAR,
    },
  ],
  mockPerformers: [
    {
      id: 1,
      name: "Alana",
      imageUrl:
        "https://twclubstd.blob.core.windows.net/taiwan-club/data/facecall-crew-img/1.jpg",
      countOfSong: 12,
      region: 2,
    },
    {
      id: 2,
      name: "Alex",
      imageUrl:
        "https://twclubstd.blob.core.windows.net/taiwan-club/data/facecall-crew-img/2.jpg",
      countOfSong: 12,
      region: 2,
    },
  ],
  mockSongs: [
    {
      id: 1,
      title: "A Worm Can Wiggle",
      category: "PA3",
      songNo: 10,
    },
    {
      id: 2,
      title: "Around the Chair",
      category: "PA2",
      songNo: 7,
    },
  ],
  regions: [
    {
      id: 1,
      region: "Hong Kong",
    },
    {
      id: 2,
      region: "Japan",
    },
    {
      id: 3,
      region: "Korea",
    },
    {
      id: 4,
      region: "Taiwan",
    },
  ],
  mockZippyFamily: [
    {
      id: 8,
      name: "Bunny",
      imageUrl:
        "https://twclubstd.blob.core.windows.net/taiwan-club/data/facecall-crew-img/8.jpg",
      countOfSong: 12,
      region: 2,
    },
  ],
  faceCallThemes: [
    {
      id: 1,
      name: "Halloween",
      startDate: "2023-10-01",
      endDate: "2023-10-31",
      inUse: true,
      iconUrl:
        "https://service.worldfamilyenglish.com/club-public-staging/data/app-service-img/face-call-halloween-2022-11-03.png?sv=2021-10-04&st=2023-09-23T05%3A39%3A15Z&se=2023-09-30T05%3A39%3A15Z&sr=c&sp=r&sig=cLiTa7mvTI2mP9l%2FXwtNK08WxbEoiummjA9BHmQo6RQ%3D",
    },
    {
      id: 2,
      name: "Christmas",
      startDate: "2023-12-01",
      endDate: "2023-12-30",
      inUse: false,
      iconUrl:
        "https://service.worldfamilyenglish.com/club-public-staging/data/app-service-img/christmas_1669944733832.png?sv=2021-10-04&st=2023-09-23T05%3A39%3A15Z&se=2023-09-30T05%3A39%3A15Z&sr=c&sp=r&sig=cLiTa7mvTI2mP9l%2FXwtNK08WxbEoiummjA9BHmQo6RQ%3D",
    },
    {
      id: 3,
      name: "New year",
      startDate: "2023-12-31",
      endDate: "2023-01-10",
      inUse: false,
      iconUrl:
        "https://service.worldfamilyenglish.com/club-public-staging/data/app-service-img/transparent.png?sv=2021-10-04&st=2023-09-23T05%3A39%3A15Z&se=2023-09-30T05%3A39%3A15Z&sr=c&sp=r&sig=cLiTa7mvTI2mP9l%2FXwtNK08WxbEoiummjA9BHmQo6RQ%3D",
    },
  ],
  mockUsers: [
    {
      id: 1,
      name: "Alinta Cheng",
      market: [RegionTypes.HONG_KONG, RegionTypes.KOREA, RegionTypes.TAIWAN],
      service: [
        ServiceTypes.CLUB_APP,
        ServiceTypes.CLUB_SERVICE,
        ServiceTypes.MKT,
        ServiceTypes.SALES_188,
        ServiceTypes.TECHPRESSO,
      ],
      isAdministrator: true,
      email: "alintacheng@worldfamilyenglish.com",
    },
    {
      id: 2,
      name: "Euna Lee",
      market: [RegionTypes.KOREA],
      service: [
        ServiceTypes.CLUB_APP,
        ServiceTypes.CLUB_SERVICE,
        ServiceTypes.MKT,
        ServiceTypes.SALES_188,
      ],
      isAdministrator: false,
      email: "eunalee@worldfamilyenglish.com",
    },
  ],
  allMarkets: [
    {
      id: 1,
      market: RegionTypes.HONG_KONG,
    },
    {
      id: 2,
      market: RegionTypes.KOREA,
    },
    {
      id: 3,
      market: RegionTypes.TAIWAN,
    },
  ],
  allServices: [
    {
      id: 2,
      service: ServiceTypes.CLUB_APP,
    },
    {
      id: 3,
      service: ServiceTypes.CLUB_SERVICE,
    },
    {
      id: 4,
      service: ServiceTypes.MKT,
    },
    {
      id: 5,
      service: ServiceTypes.SALES_188,
    },
    {
      id: 6,
      service: ServiceTypes.TECHPRESSO,
    },
    {
      id: 7,
      service: ServiceTypes.CHECK_IN,
    },
    {
      id: 8,
      service: ServiceTypes.TRIAL_MODE,
    },
  ],
  mockProgramSetting: [
    {
      service: ServiceTypes.CLUB_APP,
      users: [
        {
          id: 1,
          name: "Alinta Cheng",
          market: [
            RegionTypes.HONG_KONG,
            RegionTypes.KOREA,
            RegionTypes.TAIWAN,
          ],
          notification: true,
          faceCall: true,
        },
        {
          id: 2,
          name: "Euna Lee",
          market: [RegionTypes.KOREA],
          notification: true,
          faceCall: false,
        },
      ],
    },
    {
      service: ServiceTypes.CLUB_SERVICE,
      users: [],
    },
    {
      service: ServiceTypes.SALES_188,
      users: [],
    },
    {
      service: ServiceTypes.MKT,
      users: [],
    },
    {
      service: ServiceTypes.TECHPRESSO,
      users: [],
    },
  ],
  allButtonLinksTo: [
    {
      id: 1,
      label: "Home",
      value: "home",
    },
    {
      id: 2,
      label: "My Page",
      value: "my-page",
    },
    {
      id: 3,
      label: "Disney Library",
      value: "disney-library",
    },
    {
      id: 4,
      label: "CAP",
      value: "cap",
    },
    {
      id: 5,
      label: "TE",
      value: "te",
    },
    {
      id: 6,
      label: "Event",
      value: "event",
    },
    {
      id: 7,
      label: "Go, Go Live!",
      value: "ggl",
    },
    {
      id: 8,
      label: "Parent's Support",
      value: "parent-support",
    },
    {
      id: 9,
      label: "ABC-Channel",
      value: "on-line-video",
    },
    {
      id: 10,
      label: "Face Call",
      value: "fc",
    },
    {
      id: 11,
      label: "Learning Point",
      value: "learning-point",
    },
    {
      id: 12,
      label: "Learning History - All",
      value: "learning-history-all",
    },
    {
      id: 13,
      label: "Learning History - CAP",
      value: "learning-history-cap",
    },
    {
      id: 14,
      label: "Learning History - TE",
      value: "learning-history-te",
    },
    {
      id: 15,
      label: "Learning History - Event",
      value: "learning-history-event",
    },
    {
      id: 16,
      label: "Learning History - GGL",
      value: "learning-history-ggl",
    },
    {
      id: 17,
      label: "Learning History - Face Call",
      value: "learning-history-fc",
    },
    {
      id: 18,
      label: "Learning History - Videos",
      value: "learning-history-videos",
    },
    {
      id: 19,
      label: "Digital Magazine",
      value: "digital-magazine",
    },
  ],
  mockAnnouncements: [
    {
      id: "1",
      image: "https://i.imgur.com/4Ey1SUl.png",
      market: ["HK"],
      actionType: "AppScreen",
      buttonLinksTo: "Events",
      buttonText: "test",
      startDate: "2023-11-01T00:00:00",
      endDate: "2023-12-01T00:00:00",
    },
    {
      id: "2",
      image: "https://i.imgur.com/pcNIGNn.png",
      market: ["KR"],
      actionType: "AppScreen",
      buttonLinksTo: "Parents Support",
      buttonText: "test",
      startDate: "2023-09-26T00:00:00",
      endDate: "2023-10-03T00:00:00",
    },
    {
      id: "3",
      image: "https://i.imgur.com/TiN0Za5.png",
      market: ["KR"],
      actionType: "none",
      buttonLinksTo: "",
      buttonText: "",
      startDate: "2023-06-08T00:00:00",
      endDate: "2023-06-22T00:00:00",
    },
  ],
  mockServiceBanners: [
    {
      id: "1",
      image: "https://i.imgur.com/D3Pg87k.png",
      service: "Go, Go Live!",
      market: ["HK", "KR", "TW"],
      startDate: "2023-10-01T00:00:00",
      endDate: "2023-10-31T00:00:00",
    },
    {
      id: "2",
      image: "https://i.imgur.com/fbQk2nB.png",
      service: "Event",
      market: ["TW"],
      startDate: "2023-04-20T00:00:00",
      endDate: "2023-04-30T00:00:00",
    },
    {
      id: "3",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "Face Call",
      market: ["TW"],
      startDate: "2023-03-20T00:00:00",
      endDate: "2023-04-19T00:00:00",
    },
  ],
  mockTemplates: [
    {
      id: "1",
      image: "https://i.imgur.com/pAAe6tL.png",
      service: "Event",
      market: ["HK"],
      theme: "",
    },
    {
      id: "2",
      image: "https://i.imgur.com/yLYRKhK.png",
      service: "Event",
      market: ["KR"],
      theme: "",
    },
    {
      id: "3",
      image: "https://i.imgur.com/RiXmmmk.png",
      service: "Event",
      market: ["TW"],
      theme: "",
    },
    {
      id: "4",
      image: "https://i.imgur.com/D3Pg87k.png",
      service: "Go, Go Live!",
      market: ["HK", "KR", "TW"],
      theme: "",
    },
    {
      id: "5",
      image: "https://i.imgur.com/jiXfHjY.png",
      service: "CAP",
      market: ["HK", "KR", "TW"],
      theme: "",
    },
    {
      id: "6",
      image: "https://i.imgur.com/FjTzL52.png",
      service: "Telephone English",
      market: ["HK", "KR", "TW"],
      theme: "",
    },
    {
      id: "7",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "Face Call",
      market: ["HK", "KR", "TW"],
      theme: "",
    },
    {
      id: "8",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "Face Call",
      market: ["HK", "KR", "TW"],
      theme: "Halloween",
    },
    {
      id: "9",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "Face Call",
      market: ["HK", "KR", "TW"],
      theme: "Christmas",
    },
    {
      id: "10",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "Face Call",
      market: ["HK", "KR", "TW"],
      theme: "New year",
    },
    {
      id: "11",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "Video",
      market: ["HK", "KR", "TW"],
      theme: "",
    },
    {
      id: "12",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "WFC Library",
      market: ["HK", "KR", "TW"],
      theme: "",
    },
    {
      id: "13",
      image: "https://i.imgur.com/0DoIYX0.png",
      service: "Parents Support",
      market: ["HK", "KR", "TW"],
      theme: "",
    },
  ],
  mockDefaultBanner: [
    {
      id: "1",
      market: "TW",
      bannerType: "Activity",
      eventType: "ABC Club",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
    },
    {
      id: "2",
      market: "HK",
      bannerType: "Show",
      eventType: "Birthday Sing Along Show",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
    },
    {
      id: "3",
      market: "KR",
      bannerType: "Camp",
      eventType: "Camp",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
    },
    {
      id: "4",
      market: "TW",
      bannerType: "Parents",
      eventType: "實體家長/外傭工作坊",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
    },
    {
      id: "5",
      market: "HK",
      bannerType: "Carnival",
      eventType: "English Carnival",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
    },
    {
      id: "6",
      market: "KR",
      bannerType: "Graduation",
      eventType: "Graduation Ceremony",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
    },
  ],
  bannerTypes: [
    {
      id: 1,
      type: "MP",
      label: "Activity",
    },
    {
      id: 2,
      type: "WA",
      label: "Show",
    },
    {
      id: 3,
      type: "Camp",
      label: "Camp",
    },
    {
      id: 4,
      type: "WK",
      label: "Parents",
    },
    {
      id: 5,
      type: "EC",
      label: "Carnival",
    },
    {
      id: 6,
      type: "GC",
      label: "Graduation",
    },
  ],
  mockTemplateBanner: [
    {
      id: "1",
      market: "TW",
      bannerType: "Activity",
      eventType: "ABC Club",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
      templateName: "July",
    },
    {
      id: "2",
      market: "HK",
      bannerType: "Show",
      eventType: "Birthday Sing Along Show",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
      templateName: "July",
    },
    {
      id: "3",
      market: "KR",
      bannerType: "Camp",
      eventType: "Camp",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
      templateName: "July",
    },
    {
      id: "4",
      market: "TW",
      bannerType: "Parents",
      eventType: "實體家長/外傭工作坊",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
      templateName: "July",
    },
    {
      id: "5",
      market: "HK",
      bannerType: "Carnival",
      eventType: "English Carnival",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
      templateName: "July",
    },
    {
      id: "6",
      market: "KR",
      bannerType: "Graduation",
      eventType: "Graduation Ceremony",
      image: "https://i.imgur.com/VawyuNj.png",
      fileName: "xxx.png",
      templateName: "July",
    },
  ],
  bannerEventTypes: [
    {
      category: "Activity",
      types: [
        {
          id: 1,
          type: "ABC Club",
        },
        {
          id: 2,
          type: "Family Fun",
        },
        {
          id: 3,
          type: "Interactive Storytelling",
        },
        {
          id: 4,
          type: "Junior Club",
        },
        {
          id: 5,
          type: "Point and Play",
        },
        {
          id: 6,
          type: "寶貝玩玩",
        },
        {
          id: 7,
          type: "來趣玩活動",
        },
        {
          id: 8,
          type: "翻轉小世界",
        },
      ],
    },
    {
      category: "Show",
      types: [
        {
          id: 1,
          type: "Birthday Sing Along Show",
        },
        {
          id: 2,
          type: "CAP Event",
        },
        {
          id: 3,
          type: "Dance Along Show",
        },
        {
          id: 4,
          type: "Play Along Show",
        },
        {
          id: 5,
          type: "The Froggy and Bunny Show",
        },
        {
          id: 6,
          type: "跟著玩活動",
        },
        {
          id: 7,
          type: "Froggy和Bunny點點名",
        },
        {
          id: 8,
          type: "布偶劇場",
        },
        {
          id: 9,
          type: "Zippy人偶秀",
        },
        {
          id: 10,
          type: "Club Show",
        },
      ],
    },
    {
      category: "Camp",
      types: [
        {
          id: 1,
          type: "Camp",
        },
        {
          id: 2,
          type: "夏令營",
        },
      ],
    },
    {
      category: "Parents",
      types: [
        {
          id: 1,
          type: "實體家長/外傭工作坊",
        },
        {
          id: 2,
          type: "實體家長講座",
        },
        {
          id: 3,
          type: "線上家長/外傭工作坊",
        },
        {
          id: 4,
          type: "線上家長講座",
        },
        {
          id: 5,
          type: "家庭成長學苑",
        },
      ],
    },
    {
      category: "Carnival",
      types: [
        {
          id: 1,
          type: "English Carnival",
        },
        {
          id: 2,
          type: "WFC Winter Fair",
        },
        {
          id: 3,
          type: "美語嘉年華",
        },
      ],
    },
    {
      category: "Graduation",
      types: [
        {
          id: 1,
          type: "Graduation Ceremony",
        },
        {
          id: 2,
          type: "畢業典禮",
        },
        {
          id: 3,
          type: "Graduation Party",
        },
      ],
    },
  ],
  mockUploadBanners: [
    {
      id: "1",
      market: "TW",
      eventType: "ABC Club",
      eventTopic: "Play along! Color 2-4",
      eventDetail:
        "Lorem ipsum dolor sit amet consectetur. Urna ultrices non ut malesuada quisque purus eleifend etiam nibh.",
      imageFileName: "xxx.png",
      image: "https://i.imgur.com/VawyuNj.png",
      eventStartDate: "2023-10-18T00:00:00",
      eventEndDate: "2023-10-23T00:00:00",
      openDate: "2023-09-01T00:00:00",
    },
    {
      id: "2",
      market: "HK",
      eventType: "Interactive Storytelling",
      eventTopic: "Share, Big Bear, Share!",
      eventDetail:
        "Lorem ipsum dolor sit amet consectetur. Urna ultrices non ut malesuada quisque purus eleifend etiam nibh.",
      imageFileName: "",
      image: "",
      eventStartDate: "2023-11-08T00:00:00",
      eventEndDate: "2023-12-23T00:00:00",
      openDate: "2023-10-01T00:00:00",
    },
  ],
  mockGoGoLiveVideos: [
    {
      id: "1",
      type: "ggl",
      yearMonth: "2023-10-01T00:00:00",
      capLevel: "Light Blue",
      vimeoId: "248757026",
      fileNameHK: null,
      fileNameKR: "kr.pdf",
      fileNameTW: "tw.pdf",
      fileNamePoster: "poster.png",
      videoFileName: "video.mp4",
    },
    {
      id: "2",
      type: "ggl",
      yearMonth: "2023-09-01T00:00:00",
      capLevel: "Blue",
      vimeoId: "248757027",
      fileNameHK: "hk.pdf",
      fileNameKR: "kr.pdf",
      fileNameTW: "tw.pdf",
      fileNamePoster: null,
      videoFileName: "video.mp4",
    },
    {
      id: "3",
      type: "oh",
      yearMonth: "2023-10-01T00:00:00",
      capLevel: "Lime",
      vimeoId: "248757028",
      fileNameHK: "hk.pdf",
      fileNameKR: "kr.pdf",
      fileNameTW: "tw.pdf",
      fileNamePoster: null,
      videoFileName: "video.mp4",
    },
    {
      id: "4",
      type: "oh",
      yearMonth: "2023-09-01T00:00:00",
      capLevel: "Green",
      vimeoId: "248757029",
      fileNameHK: "hk.pdf",
      fileNameKR: "kr.pdf",
      fileNameTW: "tw.pdf",
      fileNamePoster: null,
      videoFileName: "video.mp4",
    },
  ],
  capFilters: [
    { text: "Light Blue", value: "Light Blue" },
    { text: "Blue", value: "Blue" },
    { text: "Green", value: "Green" },
    { text: "Lime", value: "Lime" },
    { text: "Yellow", value: "Yellow" },
  ],
};
